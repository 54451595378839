import React, { useState, useEffect } from 'react';
import { Button, Container, Rating, Typography } from '@mui/material';
//import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/functions';
import '../../Profile.css'
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import moment from 'moment';
import axios from 'axios';

import weburl from '../pages/kok.png'
function App() {
  const [businessname, setbusinessname] = useState('');
  const [businessadress, setbusinessadress] = useState('');
  const [reservationdata, setreservationdata] = useState([]);
  const [logo, setlogo] = useState('');
  const [numDiscount, setnumDiscount] = useState('');
  const [numMinBill, setnumMinBill] = useState('');
  const [numOfferMax, setnumOfferMax] = useState('');
  const [strOfferDesc, setstrOfferDesc] = useState('');
  const [strOfferName, setstrOfferName] = useState('');
  const [strOfferType, setstrOfferType] = useState('');
  const [numMinUCost, setnumMinUCost] = useState('');
  const [numScost, setnumScost] = useState('');
  const [strCurrency, setstrCurrency] = useState('');
  const [strRaddress, setstrRaddress] = useState('');
  const [strService, setstrService] = useState('');
  const [numBookingTotalCost,setnumBookingTotalCost]=useState(0);
  const [BID256,setBID256]=useState('')
  const [numBookingOfferCost,setnumBookingOfferCost]=useState(0)
  const [numGoldies,setnumGoldies]=useState(0)
  
  const { id } = useParams();
  
  const [country,lang,orderid ] = id.split('_');
  
  
 useEffect(() => {
  fetchData(country);
    ;
  }, []);
  
 const fetchData = async (countrycode) => {
  console.log('incountry',countrycode)
  if(countrycode=='in'){
    const response=await fetch(`${process.env.REACT_APP_API_GETORDERDATAIN_URL}?parameter=${orderid}`)
    const result = await response.json();
    console.log(result)
    setbusinessname(result.Bname)
    setbusinessadress(result.Badress)
    setlogo(result.Logo)
    setreservationdata(result.Reservedata)
    setnumDiscount(result.numDiscount)
    setnumMinBill(result.numMinBill)
    setnumOfferMax(result.numOfferMax)
    setnumScost(result.numScost)
    setstrCurrency(result.strCurrency)
    setstrOfferDesc(result.strOfferDesc)
    setstrOfferName(result.strOfferName)
    setstrOfferType(result.strOfferType)
    setstrRaddress(result.strRaddress)
    setstrService(result.strService)
    setnumMinUCost(result.numMinUCost)
    setnumBookingTotalCost(result.numBookingTotalCost)
    setBID256(result.BID256)
    setnumBookingOfferCost(result.numBookingOfferCost)
    setnumGoldies(result.numGoldies)
  }
  if(countrycode=='ee'){
  const response=await fetch(`${process.env.REACT_APP_API_GETORDERDATAEE_URL}?parameter=${orderid}`)
  const result = await response.json();
  console.log(result)
  setbusinessname(result.Bname)
  setbusinessadress(result.Badress)
  setlogo(result.Logo)
  setreservationdata(result.Reservedata)
  setnumDiscount(result.numDiscount)
  setnumMinBill(result.numMinBill)
  setnumOfferMax(result.numOfferMax)
  setnumScost(result.numScost)
  setstrCurrency(result.strCurrency)
  setstrOfferDesc(result.strOfferDesc)
  setstrOfferName(result.strOfferName)
  setstrOfferType(result.strOfferType)
  setstrRaddress(result.strRaddress)
  setstrService(result.strService)
  setnumBookingTotalCost(result.numBookingTotalCost)
  setBID256(result.BID256)
  setnumMinUCost(result.numMinUCost)
  setnumBookingOfferCost(result.numBookingOfferCost)
  setnumGoldies(result.numGoldies)
  }
 }
 let symbol;

 switch (strCurrency) {
   case 'EUR':
     symbol = '€'; // Euro symbol
     break;
   case 'INR':
     symbol = '₹'; // Indian Rupee symbol
     break;
   default:
     symbol = '';}
     const showLoader = () => {
      return (
        <div className="bouncing-ball">
          
        
        <div className="bouncing-ball">
          
          </div>
        </div>
      );
    }
  return (
  
    <div className="maindiv">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
     
          <div className='orderheader'>
            <div style={{color:'#e75600',marginTop:15,fontWeight:'bold',fontFamily:'Lexend Deca',marginLeft:5,textAlign:'center'
          }}>{orderid}</div>
          </div> 
          {reservationdata!=[]?(
    <div className="orderpagecontent">
    <div className="business-info">
      {logo?(
      <div className="image-container">
        <img src={logo}  className="person-image" />
      </div>):null}
      <div className="text-container">
        <p className='busname'>{businessname}</p>
        <p className='busaddress'>{businessadress}</p>
      </div>
    </div>
    {reservationdata?(
      <div>
         {reservationdata.map(item => (
        <div key={item.id}>
         <div style={{display: 'flex', flexDirection: 'column', 
              marginTop: 20,marginLeft:'5%',fontSize: 12, fontFamily: 'Lexend Deca',
              color: '#808080', fontWeight: 'bold'}}>
  <span >
    {moment(item.data.dtRdate).format('DD-MMM-YYYY')}
  </span>
  <span >
    {moment(item.data.dtServStart._seconds * 1000).format('HH:mm A')}---{moment(item.data.dtServEnd._seconds * 1000).format('HH:mm A')}
  </span>
</div> 
<p style={{marginTop:-1}}>
 {item.data.strSubitemLabel?( <span style={{
    backgroundColor: '#64aa64',
    width: '60px',  // increased width
    height: '25px',
    marginLeft: '4.5%',
    fontFamily: 'Lexend Deca',
    color: 'white',
    borderRadius: '8px',
    fontWeight:'bold',
    fontSize: '10px',  // set font size
    display: 'inline-block',
    lineHeight: '25px',  // vertically center the text
    textAlign: 'center'  // horizontally center the text
  }}>
    {item.data.strSubitemLabel}
  </span>):null}
  {item.data.strService?(
  <span style={{
    backgroundColor: '#6464aa',
    width: '100px',  // increased width
    height: '25px',
    fontWeight:'bold',
    fontSize: '10px',
    marginLeft: '1%',
    fontFamily: 'Lexend Deca',
    color: 'white',
    borderRadius: '8px',
    display: 'inline-block',
    lineHeight: '25px',  // vertically center the text
    textAlign: 'center'  // horizontally center the text
  }}>
    {item.data.strService}
  </span>):null}
</p>


          <div
           style={{backgroundColor:'#fff4d4',borderRadius:20,margin:5,marginTop:-10,
           boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)'}}>
           
           <a href={`https://catalog-item.web.app/en_${BID256}_${item.data.strBsubitemID}`} style={{textDecoration:'none',color:'black'}}>
           <div
            style={{textAlign:'left',marginLeft:7}}>
          <img id="weburl"  src={item.itemimage} className='orderitemimage' ></img>
       
          </div>
          <div style={{marginTop:-70,display:'flex',height:30,marginLeft:'20%',
    fontFamily: 'Lexend Deca',}}>
          
           <div style={{marginLeft:15,fontSize:12,
            backgroundColor:'white',borderRadius:5,width:25,marginTop:7,fontWeight:'bold'}}>
            <div style={{textAlign:'center',marginTop:2}}>{' '}x{item.data.numRqty}</div>
            
            </div>
            
           <div style={{color:'#e75600',marginLeft:'2%',flex:1,marginTop:10,
           fontSize:12,fontWeight:'bold'}}>{item.data.customitemname}</div>
           </div>
           
           <div style={{marginLeft:'20%',display:'flex',marginBottom:10,height:30,marginTop:10,
           fontWeight:'bold',
           fontFamily: 'Lexend Deca',}}>
          <div style={{marginLeft:20,fontSize:12,}}>#{item.data.numBstatusActual+1}</div>
          <div style={{fontSize:12,
            backgroundColor:'white',borderRadius:5,width:150,marginLeft:'3%',marginBottom:10,fontWeight:'bold'}}>
             <div style={{textAlign:'center',marginTop:2.5}}> {symbol}{item.data.numTotalCost}</div>
              </div>
              
          </div>
          </a>
          </div>
          {item.data.strRstatus=='Ready'?(
               <div>
               <p style={{textAlign:'center',fontSize:12,color:'#6464AA',fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}><span >READY</span>
               <i style={{fontSize:12,color:'#6464AA',marginLeft:2}} class="fa fa-check-square-o"></i></p>
             </div>
            ):null}
             {item.data.strRstatus=='Occupied1'?(
               <div>
               <p style={{textAlign:'center',fontSize:12,color:'#64AA64',fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}><span >CHECKED-IN</span>
               <i style={{fontSize:12,color:'#64AA64',marginLeft:2}} class="fa fa-handshake-o"></i></p>
             </div>
            ):null} 
             {item.data.strRstatus=='Reserved1'?(
              <div>
               <p style={{textAlign:'center',fontSize:12,color:'#AA6464',fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}><span >BOOKED</span>
               <i style={{fontSize:12,color:'#AA6464',marginLeft:2}} class="fa fa-bell"></i></p>
             </div>
            ):null}
            {item.data.strRstatus=='Complete'?(
            <div>
            <p style={{textAlign:'center',fontSize:12,color:'violet',fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}><span >COMPLETED</span>
            <i style={{fontSize:12,color:'violet',marginLeft:2}} class="fa fa-star"></i></p>
          </div>
           ):null}
            {item.data.strRstatus=='Cancelled'?(
               <div>
               <p style={{textAlign:'center',fontSize:12,color:'grey',fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}><span >CANCELLED</span>
               <i style={{fontSize:12,color:'grey',marginLeft:2}} class="fa fa-times-rectangle"></i></p>
             </div>
            ):null}
        </div>
      ))}
        </div>
    ):null}
  
   {businessname?(<div>
     <hr style={{marginTop:18}}></hr>
     <p style={{marginLeft:'5%',fontSize:10,fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}>{strOfferDesc}</p>
    <p className='costbreakdown'>
    <span>Discount %</span>
    <span style={{ textAlign: 'right' }}>{numDiscount}</span>
    </p> 
    <p className='costbreakdown'>
    <span>Max Discount</span>
    <span style={{ textAlign: 'right' }}>{numOfferMax}</span>
    </p> <p className='costbreakdown'>
    <span>Min UnitCost </span>
    <span style={{ textAlign: 'right' }}>{numMinUCost}</span>
    </p> <p className='costbreakdown'>
    <span>Min Bill</span>
    <span style={{ textAlign: 'right' }}>{numMinBill}</span>
    </p> 
     <hr style={{marginTop:15}}></hr>
     <p style={{marginLeft:'5%',fontSize:12,
    fontFamily: 'Lexend Deca',}}>Location</p>
     <p style={{marginLeft:'5%',fontSize:12,fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}>{strRaddress}</p>
     <p style={{marginLeft:'5%',fontSize:12,marginTop:25,
    fontFamily: 'Lexend Deca',}}>Bill</p>
     
     <span style={{
    backgroundColor: 'grey',
    width: '70px',  // increased width
    height: '30px',
    marginLeft: '4%',
    fontFamily: 'Lexend Deca',
    color: 'white',
    borderRadius: '20px',
    fontWeight:'bold',
    marginBottom:5,
    fontSize: '12px',  // set font size
    display: 'inline-block',
    lineHeight: '30px',  // vertically center the text
    textAlign: 'center'  // horizontally center the text
  }}>
    {symbol}{numScost+numBookingTotalCost}
  </span>
     <span style={{marginLeft:'2%',color:'grey',fontSize:12,marginTop:25,fontWeight:'bold',
    fontFamily: 'Lexend Deca',}}>{reservationdata[0].data.strPayStatus}</span>
     <hr style={{marginTop:18}}></hr>
    
     <p className='costbreakdown'>
    <span>Cost including taxes</span>
    <span style={{ textAlign: 'right' }}>{numBookingTotalCost}</span>
    </p> 
    <p className='costbreakdown'>
    <span>Service cost</span>
    <span style={{ textAlign: 'right' }}>{numScost?numScost:0}</span>
    </p> <p className='costbreakdown'>
    <span>Offer</span>
    <span style={{ textAlign: 'right' }}>{numBookingOfferCost?numBookingOfferCost:0}</span>
    </p> <p className='costbreakdown'>
    <span>Goldies</span>
    <span style={{ textAlign: 'right' }}>{numGoldies?numGoldies:0}</span>
    </p> <p className='costbreakdown' style={{marginBottom:40}}>
    <span style={{fontWeight:'bold'}}>Bill</span>
    <span style={{ textAlign: 'right',fontWeight:'bold' }}>{numScost+numBookingTotalCost}</span>
    </p> 
</div>):( <div style={{marginLeft:"45%",marginTop:'20%'}}>
     <div className="bouncing-ball">
   {showLoader()}
</div>
</div>)}
     </div>):
     <div style={{marginLeft:"45%",marginTop:'20%'}}>
     <div className="bouncing-ball">
   {showLoader()}
</div>
</div>}
     </div>
    
  );
};

export default App;