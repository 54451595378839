
import React from 'react';
import './App.css';
//import Navbar from './components/Navbar';
//import Navbar from './components/Navbarelements/index'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
//import Home from './pages';
import Galleryorder from './components/pages/galleryorder'
function App() {
  return (

      <Router>
    
      <Routes>
         
          <Route path='/:id' element={<Galleryorder/>}/>
      </Routes>
      </Router>
  );
  }
    
  export default App;